import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { filter, finalize, map, switchMap, tap } from 'rxjs/operators';
import { Params } from '@angular/router';
import { RouterParamsService } from '../../../../shared/services/router-params.service';

@Injectable()
export class BaseEntityService<T> {
  private entitiesSubject = new BehaviorSubject<T[]>([]);
  private loadingSubject = new BehaviorSubject<boolean>(false);
  private entitiesMap = new Map<string, T>();
  private _paginationSubject = new Subject<T[]>();

  public singularEntityName: string;
  public pluralEntityName: string;
  public httpClient: HttpClient;
  public nameId: string;
  private routerParamsService: RouterParamsService;

  public entityUrl: any;

  constructor(
    private http: HttpClient,
    baseUrl: string,
    entityNames: { singular: string; plural: string, url?: string },
    routerParamsService: RouterParamsService
  ) {
    const { singular, plural, url } = entityNames;
    this.singularEntityName = singular;
    this.pluralEntityName = plural;
    this.entityUrl = [baseUrl, url ?? plural].join('/');
    this.httpClient = http;
    this.routerParamsService = routerParamsService;
  }

  get paginationSubject(): Subject<T[]> {
    return this._paginationSubject;
  }

  set paginationSubject(value: T[]) {
    this._paginationSubject.next(value);
  }

  get getEntities$(): Observable<T[]> {
    return this.entitiesSubject.asObservable();
  }

  get getEntities() {
    return [...this.entitiesMap.values()];
  }

  clearAll(): void {
    this.loadingSubject.next(false);
    this.entitiesSubject.next([]);
    this.entitiesMap.clear();
  }

  set manualEntity(newEntity: T) {
    const entities = [...this.entitiesSubject.getValue(), newEntity];
    this.entitiesMap.set((newEntity as any).id, newEntity);
    this.entitiesSubject.next(entities);
  }

  public getCurrent(): Observable<T> {
    console.log('getCurrent');
    return this.routerParamsService.params.pipe(
      tap(params => {
        console.log('params', params);
      }),
      filter(params => !!params[this.nameId]),
      map(params => params[this.nameId]),
      switchMap(id => this.getEntityById(id)),
    );
  }

  public getCurrentFromApi(firstAvailable = false): Observable<T> {
    return this.routerParamsService.params.pipe(
      filter(params => !!params[this.nameId]),
      map(params => parseInt(params[this.nameId], 10)),
      switchMap(ids => {
          if (firstAvailable) {
            const tFinded = this.getEntities.find(t => (t as any).id === ids);
            if (tFinded) {
              return of(tFinded);
            }
          }
          return this.getWithQuery({ ids }).pipe(
            map(response => {
              const [organization] = response;
              return organization;
            }),
          );
        }
      ),
    );
  }

  getWithQuery(queryParams: Params, disableDefaultWrap: boolean = false): Observable<T[]> {
    this.loadingSubject.next(true);
    const params: Params = this.removeUndefinedOrNull(queryParams);
    return this.http.get<T[]>(this.entityUrl, { params }).pipe(
      map(response => disableDefaultWrap ? response ?? [] : response[this.pluralEntityName] ?? []),
      tap(entities => {
        if (!disableDefaultWrap) {
          this.entitiesMap.clear();
          entities.forEach((entity: T) => this.entitiesMap.set((entity as any).id, entity));
          this.entitiesSubject.next(this.getEntities);
          this._paginationSubject.next(this.getEntities);
        }
      }),
      finalize(() => this.loadingSubject.next(false))
    );
  }

  add(entity: T, disableDefaultWrap: boolean = false): Observable<T> {
    const body = disableDefaultWrap ? entity : { [this.singularEntityName]: entity };
    this.loadingSubject.next(true);
    return this.http.post<T>(this.entityUrl, body).pipe(
      map(response => disableDefaultWrap ? response ?? {} : response[this.singularEntityName] ?? {}),
      tap(newEntity => {
        if (!disableDefaultWrap) {
          this.entitiesMap.set((newEntity as any).id, newEntity);
          this.entitiesSubject.next(this.getEntities);
        }
      }),
      finalize(() => this.loadingSubject.next(false))
    );
  }

  update(entity: T, disableDefaultWrap: boolean = false): Observable<T> {
    const body = disableDefaultWrap ? entity : { [this.singularEntityName]: entity };
    this.loadingSubject.next(true);
    const { id } = entity as any;
    return this.http.patch<T>([this.entityUrl, id].join('/'), body).pipe(
      map(response => disableDefaultWrap ? response ?? {} : response[this.singularEntityName] ?? {}),
      tap(updatedEntity => {
        if (!disableDefaultWrap) {
          this.entitiesMap.set(id, updatedEntity);
          this.entitiesSubject.next(this.getEntities);
        }
      }),
      finalize(() => this.loadingSubject.next(false))
    );
  }

  delete(id: number): Observable<void> {
    this.loadingSubject.next(true);
    return this.http.delete<void>([this.entityUrl, id].join('/')).pipe(
      tap(() => {
        try {
          this.entitiesMap.delete(id.toString());
          this.entitiesSubject.next(this.getEntities);
        } catch (e) {
          console.error(e);
        }
      }),
      finalize(() => this.loadingSubject.next(false))
    );
  }

  saveEntity(enity: T): Observable<T> {
    if ((enity as any).id) {
      return this.update(enity);
    }
    return this.add(enity);
  }

  getById(ids: any): Observable<T> {
    this.loadingSubject.next(true);
    return this.http.get<T>([this.entityUrl].join('/'), {
      params: {
        ids
      }
    }).pipe(
      map(response => response[this.pluralEntityName] ?? []),
      map(response => {
        const [entity] = response;
        return entity;
      }),
      tap((updatedEntity) => {
        this.entitiesMap.set(ids, updatedEntity);
        this.entitiesSubject.next(this.getEntities);
      }),
      finalize(() => this.loadingSubject.next(false))
    );
  }

  getByIds(id: any): Observable<T> {
    return this.getById(id);
  }

  getEntityById(id: any): Observable<T> {
    id = parseInt(id, 10);
    this.loadingSubject.next(true);
    return this.getEntities$.pipe(
      filter(entities => entities.length > 0 && entities.some(e => (e as any).id === id)),
      map(entities => entities.find(e => (e as any).id === id)),
      finalize(() => this.loadingSubject.next(false))
    );
  }

  removeUndefinedOrNull(obj: { [key: string]: any }): { [key: string]: any } {
    const cleanedObj: { [key: string]: any } = {};
    Object.keys(obj).forEach(key => {
      const value = obj[key];
      if (value !== undefined && value !== null) {
        cleanedObj[key] = value;
      }
    });
    return cleanedObj;
  }
}
