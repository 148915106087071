import {
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { Menu, MenuLayoutService } from '../../../services/menu-layout.service';
import { JwtAuthService } from '../../../services/auth/jwt-auth.service';
import { Person } from '../../../../state/models/person';
import { MatSidenav } from '@angular/material/sidenav';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { distinctUntilChanged, filter, skip, startWith } from 'rxjs/operators';
import { OrganizationService } from '../../../services/organization.service';
import { AutoUnsubscribe, CombineSubscriptions } from '../../../decorators/auto-unsubscribe.decorator';
import { combineLatest, Unsubscribable } from 'rxjs';
import { Organization } from '../../../../state/models/organization';
import { RouterParamsService } from '../../../services/router-params.service';
import { ViewportScroller } from '@angular/common';
import { LoginService } from '../../../../views/sessions/services/login.service';
import { MenuActionsService } from '../../../services/menu-actions.service';
import { isEqual } from 'lodash';

@Component({
    selector: 'menu-layout',
    templateUrl: './menu-layout.component.html',
    styles: `
    .example-spacer {
      flex: 1 1 auto;
    }

    .toolbar-item-spacer {
      flex: 1 1 auto;
    }

    .app-container {

      .body-container {
        height: 100vh;
        overflow-y: auto;

        @supports (height: 100dvh) {
          height: 100dvh;
        }

      }
    }

    .app-sidenav-content {
      height: calc(100vh - 64px);
      @media screen and (max-width: 600px) {
        height: calc(100vh - 56px);
      }

      &.full {
        height: 100vh;
      }

      @supports (height: 100dvh) {
        height: calc(100dvh - 64px);
        @media screen and (max-width: 600px) {
          height: calc(100dvh - 56px);
        }
        &.full {
          height: 100dvh;
        }
      }
    }

    mat-list-item {
      .mat-mdc-list-item-unscoped-content {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  `,
    standalone: false
})
@AutoUnsubscribe()
export class MenuLayoutComponent implements OnInit, AfterViewInit, AfterViewChecked, OnDestroy {
  toolbarNav: boolean;
  user: Person;
  @ViewChild('sidenav') drawer: MatSidenav;
  organization: Organization;
  title: string;
  menu: Menu[] = [];
  menuFooter: Menu[] = [];
  params: any = {};
  @CombineSubscriptions()
  private subscriptions: Unsubscribable;
  routerChangeLastId: number = 0;

  openMenuMode: 'over' | 'push' | 'side' = 'side';

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.setModeOpenMenu();
  }


  @ViewChild('container') container: ElementRef;

  constructor(
    private menuActionsService: MenuActionsService,
    private loginService: LoginService,
    private cdr: ChangeDetectorRef,
    private route: ActivatedRoute,
    private router: Router,
    private routerParamsService: RouterParamsService,
    private jwtAuthService: JwtAuthService,
    private menuLayoutService: MenuLayoutService,
    public organizationService: OrganizationService,
    private viewportScroller: ViewportScroller
  ) {
    this.setModeOpenMenu();
    this.organization = organizationService.getOrganization();

    this.subscriptions = this.jwtAuthService.user$.asObservable().subscribe({
      next: () => {
        this.user = jwtAuthService.getUser();
        this.organization = organizationService.getOrganization();
      }
    });

    this.subscriptions = this.menuLayoutService.layoutConf$
      .pipe(
        skip(1),
        distinctUntilChanged((prevConfig, currConfig) => isEqual(prevConfig, currConfig))
      )
      .subscribe(config => {
        this.title = config.titlePage;
        this.toolbarNav = config.toolbarNav;
        this.menu = config.menu ? [...config.menu] : [];
        this.menuFooter = config.menuFooter || [];
        this.user = jwtAuthService.getUser();

        this.menu = this.menuActionsService.setActions([...this.menu], this.params);
        this.menuFooter = this.menuActionsService.setActions([...this.menuFooter], this.params);
      });


    this.subscriptions = combineLatest([
      this.routerParamsService.params,
      this.router.events.pipe(
        filter(event => event instanceof NavigationEnd),
        startWith(null)
      )
    ])
      .subscribe(([params, routeChange]) => {
        this.params = params;
        if (routeChange && this.drawer) {
          this.drawer.close().then();
        }

        if (routeChange) {
          setTimeout(() => {
            this.setToTop();
          }, 0);
        }

        this.organization = this.organizationService.getOrganization();
        if (routeChange instanceof NavigationEnd) {
          if (routeChange.id > this.routerChangeLastId) {
            this.routerChangeLastId = routeChange.id;
            this.menuLayoutService.updateLayoutConfig();
          }
        }

      });
  }

  ngOnInit() {
  }

  ngOnDestroy() {
  }

  ngAfterViewInit() {
  }

  setToTop() {
    this.viewportScroller.scrollToPosition([0, 0]);
    if (this.container) {
      this.container.nativeElement.scrollTop = 0;
    }
  }

  ngAfterViewChecked() {
    this.cdr.detectChanges();
  }

  logout() {
    this.jwtAuthService.logout();
  }

  setModeOpenMenu(): void {
    window.innerWidth <= 600 ? this.openMenuMode = 'over' : this.openMenuMode = 'side';
  }

  openSupport() {
    const msg = `Olá, preciso de suporte com o painel ${ this.organization.name }`;
    this.loginService.sendWhatsAppMessage(msg);
  }

  toggle() {
    this.drawer.toggle();
  }

  protected readonly window = window;
}
