import { Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Platform } from '@angular/cdk/platform';
import { AutoUnsubscribe, CombineSubscriptions } from '../../decorators/auto-unsubscribe.decorator';
import { Unsubscribable } from 'rxjs';

@Component({
    selector: 'metabase-stats',
    templateUrl: './metabase-stats.component.html',
    styleUrls: ['./metabase-stats.component.scss'],
    standalone: false
})
@AutoUnsubscribe()
export class MetabaseStatsComponent implements OnInit, OnChanges, OnDestroy {
  @CombineSubscriptions()
  subscriptions: Unsubscribable;

  @Input() reportUrl: string;
  url: string;
  showSpinner: boolean;
  @ViewChild('iframeId', { static: false }) iframeId: ElementRef;

  constructor(private route: ActivatedRoute, public platform: Platform) {
    this.subscriptions = this.route.data.subscribe(res => {
      if (res?.organization) {
        const { results } = res.organization;
        this.setReportUrl(results[0].url);
      }
    });
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    this.setupReport();
  }

  ngOnDestroy() {
  }

  setupReport() {
    this.showSpinner = true;
    if (this.reportUrl) {
      this.setReportUrl(this.reportUrl);
    }
  }

  loadSuccess() {
    this.showSpinner = false;
  }

  setReportUrl(reportUrl: string) {
    if (reportUrl) {
      this.url = [reportUrl, '#bordered=false&titled=false'].join('');
    }
  }

}
