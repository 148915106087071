import { AfterViewInit, Component } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';

@Component({
    selector: 'formly-field-toggle-type',
    styleUrl: './formly-field-toggle-type.component.scss',
    template: `
    <div class="button-toggle-form">
      <label *ngIf="props?.label">{{ props.label }}</label>
      <div>
        <mat-button-toggle-group [formlyAttributes]="field" [tabIndex]="props.tabindex" [formControl]="formControl"
                                 [value]="value">
          @for (item of opt; track $index) {
            <mat-button-toggle [value]="item.value">{{ item.label }}</mat-button-toggle>
          }
        </mat-button-toggle-group>
      </div>
    </div>
  `,
    standalone: false
})
export class FormlyFieldToggleTypeComponent extends FieldType<FieldTypeConfig> implements AfterViewInit {
  value: any;

  constructor() {
    super();
  }

  get opt(): any[] {
    return this.props?.options || [] as any;
  }

  ngAfterViewInit() {
    if (this?.model && this?.key) {
      this.value = this?.model[this?.key as any];
    }
  }
}
