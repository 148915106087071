import { Component, OnInit } from '@angular/core';
import { TitleService } from './shared/services/title.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { Title } from '@angular/platform-browser';
import { RoutePartsService } from './shared/services/route-parts.service';
import { environment } from '../environments/environment';
import { AmplitudeService } from './shared/services/amplitude.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrl: './app.component.scss',
    standalone: false
})
export class AppComponent implements OnInit {
  appTitle = environment.company.name;
  pageTitle = '';

  constructor(
    private amplitudeService: AmplitudeService,
    public title: Title,
    private router: Router,
    private activeRoute: ActivatedRoute,
    private routePartsService: RoutePartsService,
    private titleService: TitleService) {
    this.titleService.setTitle('Início');
  }

  ngOnInit() {
    this.amplitudeService.init(undefined)
    this.changePageTitle();
  }


  changePageTitle() {
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(routeChange => {
        const routeParts = this.routePartsService.generateRouteParts(
          this.activeRoute.snapshot,
        );
        if (!routeParts.length) {
          return this.title.setTitle(this.appTitle);
        }
        // Extract title from parts;
        this.pageTitle = routeParts
          .reverse()
          .map(part => part.title)
          .reduce((partA, partI) => {
            return `${ partA } > ${ partI }`;
          });
        this.pageTitle += ` | ${ this.appTitle }`;
        this.title.setTitle(this.pageTitle);
      });
  }
}
