import { Injectable } from '@angular/core';
import { RouterParamsService } from '../../shared/services/router-params.service';
import { Referral } from '../models/referral';
import { BaseEntityService } from '../entity-management/services/base/base-entity.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ReferralEntityService extends BaseEntityService<Referral> {
  constructor(
    httpClient: HttpClient,
    routerParamsService: RouterParamsService,
  ) {
    super(
      httpClient,
      environment.apiUrl, {
        singular: 'referral',
        plural: 'referrals',
      },
      routerParamsService
    );
    this.nameId = 'referralId';
  }

  getDocumentUrl(id: any): Observable<Referral> {
    return this.httpClient.post(`${this.entityUrl}/${id}/get-document-url`, {}).pipe(
      map(response => {
        return response['referral']  as Referral;
      })
    )
  }
}
