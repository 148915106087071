import { Directive, Input, Renderer2, ElementRef, OnInit, OnDestroy } from '@angular/core';

@Directive({
    selector: '[showOnSize]',
    standalone: false
})
export class ShowOnSizeDirective implements OnInit, OnDestroy {
  @Input('showOnSize') size: 'mobile' | 'desktop' = 'mobile';
  private mediaQueryList: MediaQueryList;
  private listener: () => void;

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  ngOnInit() {
    this.updateVisibility();
  }

  ngOnDestroy() {
    this.removeListener();
  }

  private updateVisibility() {
    const mobileQuery = '(max-width: 600px)';
    const desktopQuery = '(min-width: 601px)';
    const query = this.size === 'mobile' ? mobileQuery : desktopQuery;

    // Remove the previous listener if it exists
    this.removeListener();

    // Create a new media query list
    this.mediaQueryList = window.matchMedia(query);
    this.listener = this.applyVisibility.bind(this);

    // Add the listener for media query changes
    this.mediaQueryList.addEventListener('change', this.listener);

    // Apply the initial visibility
    this.applyVisibility();
  }

  private applyVisibility() {
    if (this.size === 'mobile') {
      if (this.mediaQueryList.matches) {
        this.renderer.removeClass(this.el.nativeElement, 'hidden-mobile');
      } else {
        this.renderer.addClass(this.el.nativeElement, 'hidden-mobile');
      }
    } else {
      if (this.mediaQueryList.matches) {
        this.renderer.removeClass(this.el.nativeElement, 'hidden-desktop');
      } else {
        this.renderer.addClass(this.el.nativeElement, 'hidden-desktop');
      }
    }
  }

  private removeListener() {
    if (this.listener && this.mediaQueryList) {
      this.mediaQueryList.removeEventListener('change', this.listener);
      this.listener = null;
      this.mediaQueryList = null;
    }
  }
}
