import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, } from '@angular/core';
import { NgxFileDropEntry } from 'ngx-file-drop';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { noop, switchMap, tap, Unsubscribable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { UtilsService } from '../../../shared/services/utils.service';
import { AutoUnsubscribe, CombineSubscriptions } from '../../../shared/decorators/auto-unsubscribe.decorator';

@Component({
    selector: 'file-upload',
    templateUrl: './file-upload.component.html',
    styleUrl: './file-upload.component.scss',
    standalone: false
})
@AutoUnsubscribe()
export class FileUploadComponent implements OnInit, OnDestroy {
  @Output() callback = new EventEmitter();
  @Input() prefix: 'PERFORMED_EXAM' | 'REFERRAL' | 'EXAM_REQUEST' | 'PATIENTS' | 'EXAM_PARSE';
  @Input() showRemoveButton: boolean;
  @Input('titleButton') titleButtonOriginal = 'Upload de arquivos';
  titleButton;
  @Input() iconButton: string;
  public files: NgxFileDropEntry[] = [];
  loading: boolean;
  @Input() disableLoading: boolean;
  @CombineSubscriptions()
  private subscriptions: Unsubscribable;

  constructor(private utilsService: UtilsService, private http: HttpClient) {
  }

  ngOnInit(): void {
    this.setOriginalMsg();
    if (this.showRemoveButton) {
      this.titleButton = 'Arquivo enviado!';
    }
  }

  ngOnDestroy() {
  }

  setOriginalMsg() {
    this.titleButton = this.titleButtonOriginal;
    this.loading = false;
  }

  setLoading() {
    if (!this.disableLoading) {
      this.titleButton = 'Enviando...';
      this.loading = true;
    }
  }

  public dropped(files: NgxFileDropEntry[]) {
    this.files = files;
    for (const droppedFile of files) {
      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => {
          const { type: contentType } = file;
          this.setLoading();
          this.sendFileLoading(file.name);
          this.subscriptions = this.http
            .post(`${ environment.apiUrl }/files`, {
              file: {
                prefix: this.prefix,
                contentType,
              },
            })
            .pipe(
              switchMap(response => {
                const { file: fileResp } = response as {
                  file: FileResponse;
                };
                return this.http.put(`${ fileResp.signedPutUrl }`, file).pipe(
                  map(() => {
                    return fileResp;
                  }),
                );
              }),
              tap(response => {
                const { url, signedGetUrl } = response;
                const { name } = file;
                this.setOriginalMsg();
                this.utilsService.toast('Arquivo enviado!', 'Ok');
                this.callback.emit({
                  id: name,
                  loading: false,
                  name,
                  url,
                  signedGetUrl,
                });
              }),
              catchError(err => {
                const { message = 'Erro ao enviar arquivo' } = err;
                this.setOriginalMsg();
                this.utilsService.toast(message, 'Ok');
                throw err;
              }),
            )
            .subscribe(noop);
        });
      }
    }
  }

  sendFileLoading(toId: any) {
    this.callback.emit({
      id: toId,
      loading: true,
      name: toId,
      url: '',
      signedGetUrl: '',
    });
  }

  public fileOver(event) {
  }

  public fileLeave(event) {
  }

  public removeFile() {
    this.callback.emit({ url: null });
    this.titleButton = 'Upload de arquivos';
  }
}


export interface FileResponse {
  id?: string;
  loading?: boolean;
  signedPutUrl: string;
  url: string;
  signedGetUrl: string;
}
