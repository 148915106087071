import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class UrlService {
  organizationsList(): string {
    return '/organizacoes';
  }

  organizationsHome(id: unknown): string {
    return this.organizationsRhs(id);
  }

  organizationsHomeDoctor(id: unknown): string {
    return [this.organizationsBase(id), 'atendimento'].join('/');
  }


  organizationsRhHome(id: unknown): string {
    return [this.organizationsBase(id), 'adesao'].join('/');
  }

  organizationsBase(id: unknown): string {
    return [this.organizationsList(), id].join('/');
  }

  organizationsProfile(id: unknown): string {
    return [this.organizationsList(), id,'perfil'].join('/');
  }

  organizationsSalesHome(id: unknown): string {
    return [this.organizationsList(), id,'vendedor', 'adesao-prevencao'].join('/');
  }

  organizationsSalesBonus(id: unknown): string {
    return [this.organizationsList(), id,'vendedor', 'bonus'].join('/');
  }

  organizationsStatsGeneralData(id: unknown): string {
    return [this.organizationsBase(id), 'estatisticas', 'dados-gerais'].join('/');
  }

  organizationsAccessControl(id: unknown): string {
    return [this.organizationsList(), id, 'controle-de-acesso'].join('/');
  }

  organizationsRhs(id: unknown): string {
    return [this.organizationsList(), id, 'rhs'].join('/');
  }
}
