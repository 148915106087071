import { Pipe, PipeTransform } from '@angular/core';
import { UtilsService } from '../services/utils.service';

@Pipe({
    name: 'phone',
    standalone: false
})
export class PhonePipe implements PipeTransform {
  constructor(private utilsService: UtilsService) {
  }

  transform(value: string): string {
    return this.utilsService.phoneFormat(value);
  }
}
