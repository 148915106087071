import { Directive, ElementRef, Input, OnDestroy, OnInit, Renderer2 } from '@angular/core';

@Directive({
    selector: '[applyClassOnSize]',
    standalone: false
})
export class ApplyClassOnSizeDirective implements OnInit, OnDestroy {
  @Input('applyClassOnSize') size: 'mobile' | 'desktop' = 'mobile';
  @Input() className: string;
  @Input() classNames: string;

  private mediaQueryList: MediaQueryList;
  private listener: () => void;

  constructor(private el: ElementRef, private renderer: Renderer2) {
  }

  ngOnInit() {
    if (this.className) {
      this.classNames = this.className;
    }
    this.updateClass();
  }

  ngOnDestroy() {
    this.removeListener();
  }

  private updateClass() {
    const mobileQuery = '(max-width: 600px)';
    const desktopQuery = '(min-width: 601px)';
    const query = this.size === 'mobile' ? mobileQuery : desktopQuery;

    // Remove the previous listener if it exists
    this.removeListener();

    // Create a new media query list
    this.mediaQueryList = window.matchMedia(query);
    this.listener = this.applyClass.bind(this);

    // Add the listener for media query changes
    this.mediaQueryList.addEventListener('change', this.listener);

    // Apply the initial class
    this.applyClass();
  }

  private applyClass() {
    const classList = this.classNames.split(' ');

    if (this.mediaQueryList.matches) {
      classList.forEach(className => {
        this.renderer.addClass(this.el.nativeElement, className);
      });
    } else {
      classList.forEach(className => {
        this.renderer.removeClass(this.el.nativeElement, className);
      });
    }
  }

  private removeListener() {
    if (this.listener && this.mediaQueryList) {
      this.mediaQueryList.removeEventListener('change', this.listener);
      this.listener = null;
      this.mediaQueryList = null;
    }
  }
}
