import { Injectable } from '@angular/core';
import { RouterParamsService } from '../../shared/services/router-params.service';
import { BaseEntityService } from '../entity-management/services/base/base-entity.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { PatientInteractionLogs } from '../models/patient-interaction-logs';

@Injectable({
  providedIn: 'root',
})
export class PatientInteractionLogsEntityService extends BaseEntityService<PatientInteractionLogs> {

  constructor(
    httpClient: HttpClient,
    routerParamsService: RouterParamsService,
  ) {
    super(
      httpClient,
      environment.apiUrl, {
        singular: 'patientInteractionLog',
        plural: 'patientInteractionLogs',
        url: 'patient-interaction-logs'
      },
      routerParamsService
    );
    this.nameId = 'patientInteractionLogId';
  }
}
