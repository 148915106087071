import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-auth-layout',
    templateUrl: './auth-layout.component.html',
    standalone: false
})
export class AuthLayoutComponent implements OnInit {
  constructor() {
  }

  ngOnInit() {
  }
}
