import { Component } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';

@Component({
    selector: 'formly-field-masked-type',
    template: `
    <mat-form-field class="full-width" appearance="outline">
      <mat-label>{{props.label}}</mat-label>
      <input
        matInput
        currencyMask
        [options]="currencyOptions"

        [id]="props.id"
        [type]="'text'"
        [readonly]="props.readonly"
        [required]="props.required"
        [formControl]="formControl"
        [formlyAttributes]="field"
        [tabIndex]="props.tabindex"
        [placeholder]="props.placeholder"
      />
    </mat-form-field>`,
    standalone: false
})
export class FormlyFieldCurrencyTypeComponent extends FieldType<FieldTypeConfig> {

  get currencyOptions() {
    return {
      align: 'left',
      prefix: 'R$ ',
      thousands: '.',
      decimal: ',',
      allowNegative: false,
    };
  }

}
