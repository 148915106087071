import { Component, HostListener } from '@angular/core';
import { LoginService } from '../../../views/sessions/services/login.service';
import { OrganizationService } from '../../services/organization.service';
import { Organization } from '../../../state/models/organization';
import { AmplitudeService } from '../../services/amplitude.service';

@Component({
    selector: 'support-button',
    template: `
    <button *ngIf="window.innerWidth > 600" (click)="openSupport()" mat-icon-button>
      <mat-icon>contact_support</mat-icon>
    </button>
  `,
    styles: `
  `,
    standalone: false
})
export class SupportButtonComponent {
  organization: Organization;

  @HostListener('window:resize', ['$event'])
  onResize() {
  }

  constructor(
    private amplitudeService: AmplitudeService,
    public organizationService: OrganizationService,
    private loginService: LoginService,
  ) {
  }

  openSupport() {
    this.amplitudeService.sendEvent('contact_support_clicked', {
      stepName: location.pathname,
    });
    this.organization = this.organizationService.getOrganization();
    const msg = `Olá, preciso de suporte com o painel ${ this.organization?.name || '' }`;
    this.loginService.sendWhatsAppMessage(msg);
  }

  protected readonly window = window;
}
